.flickity-slider {
    position: relative;
}

.video__overlay {
    width: 100%;
    height: 100%;
    position: relative;
}

.video__overlay-inner {
    text-align: center;
    @include align-both(absolute);

    @include below(640px) {
        width: 100%;
        padding: 0 15px;
    }
}

.video__button {
    width: 88px;
    height: 88px;
    display: inline-block;
    background: #fff;
    position: relative;
    border-radius: 100%;
    transition: all .2s ease-in-out;
    background-image: url('../img/playbutton.png');
    background-position: center center;
    background-repeat: no-repeat;

    &:hover {
        background: $red;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/playbuttonwhite.png');
    }

    @include below(640px) {
        width: 68px;
        height: 68px;
    }
}

.video__text {
    color: #fff;
    display: block;
    font-size: 28px;
    font-style: italic;
    margin-top: 25px;
    font-family: $garamond;

    @include below(640px) {
        font-size: 22px;
    }
}

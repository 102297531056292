// Clearfix

@mixin clearfix() {
	&:before,
	&:after {
	    content: "";
	    display: table;
	}
	&:after {
	    clear: both;
	}
}

// Line up.
@mixin vertical-align($position: relative){
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin vertical-align($position: relative){
	  position: $position;
	  top: 50%;
	  transform: translateY(-50%);
}

@mixin horizontal-align($position: relative){
	  position: $position;
	  left: 50%;
	  transform: translateX(-50%);
}

@mixin align-both($position: relative){
	 position: $position;
	 left: 50%;
	 top:50%;
	 transform: translate(-50%, -50%);
}

@mixin no-align($position:relative){
	position:initial !important;
	left: initial !important;
	top: initial !important;
	transform:none !important;
}

// Lets dance with the gradients
@mixin gradient($top, $bottom, $base, $min:0%,$max:100%){
    background: $base;
    background-image: -moz-linear-gradient(bottom,  $bottom $min, top $max);
    background-image: -o-linear-gradient(bottom, $bottom $min, top $max);
    background-image: -webkit-linear-gradient(bottom, $bottom $min,  top $max);
    background-image: linear-gradient(bottom, $bottom $min, $top $max);
}
@mixin gradientHorizontal($left, $right, $base, $min:0%,$max:100%){
    background: $base; /* Old browsers */
    background: -moz-linear-gradient(left, $left $min, $right $max); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop($min,$left), color-stop($max,$right)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, $left $min,$right $max); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, $left $min,$right $max); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, $left $min,$right $max); /* IE10+ */
    background: linear-gradient(to right, $left $min,$right $max); /* W3C */
}

.startpage {
    width: 100%;
    height: 100%;
    position: relative;
    @include clearfix();
}

.startpage__col {
    width: 50%;
    height: 100%;
    display: block;
    float: left;

    &:first-of-type {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    @include below(980px) {
        width: 100%;
        height: auto;

        &:first-of-type {
            height: 40%;
        }
    }
}

.startpage__content {
    width: 100%;
    padding: 0 20px;
    text-align: center;
    @include align-both();

    .dropdown {
        margin-top: 70px;

        @include below(460px) {
            margin-top: 40px;
        }
    }

    @include below(980px) {
        top: auto;
        left: auto;
        margin: 60px 0;
        transform: none;
    }
}

.startpage__content-title {
    margin-bottom: 35px;
}

.startpage__content-text {
    font-size: 20px;
    line-height: 32px;
    max-width: 490px;
    margin: 0 auto;
    font-family: 'sf_ui_displayregular';

    @include below(460px) {
        font-size: 16px;
        line-height: 28px;
    }
}

.startpage__logo {
    color: #fff;
    display: block;
    font-size: 72px;
    text-align: center;
    font-family: $garamond;
    @include vertical-align();
    text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    -webkit-text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    animation: fadeInDown 2s;

    @include below(460px) {
        font-size: 42px;
    }
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
        top: 45%;
	}
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
	100% {
		opacity: 1;
        top: 50%;
	}
}

.startpage__content .dropdown__select {
    @include below(1380px) {
        width: 100%;
        max-width: 400px;
        display: block;
        margin: 20px auto 0 auto;
    }
    @include below(460px) {
        max-width: none;
    }
}

.startpage__content .button-r {

    @include below(1380px) {
        display: block;
        max-width: 70%;
        margin: 40px auto;
    }
    @include below(460px) {
        max-width: none;
        padding: 5px 50px;
        min-height: none;
    }
}

.text-module {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;

    @include below(640px) {
        padding: 0 20px;
    }
}

.text-module__title {
    max-width: 467px;
    text-align: center;
    margin: 0px auto 60px auto;

    @include below(420px) {
        margin-bottom: 40px;
    }
}

.text-module__text {
    font-size: 20px;
    line-height: 32px;
    font-family: 'sf_ui_displayregular';

    @include below(640px) {
        font-size: 16px;
        line-height: 26px;
    }

    a {
        color: $red;
    }
}

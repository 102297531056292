body,
html {
    width: 100%;
    height: 100%;
    position: relative;
}

body {
    animation: fadeIn 1.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// Text
h2, h3,
h4 {
    font-family: 'GaramondPremrPro-Med';
}

h2 {
    font-size: 46px;
    line-height: 52px;

    @include below(640px) {
        font-size: 36px;
        line-height: 42px;
    }
}

h3 {
    font-size: 36px;
    line-height: 52px;

    @include below(420px) {
        font-size: 30px;
        line-height: 42px;
    }
    @include below(320px) {
        font-size: 26px;
    }
}

h4 {
    font-size: 28px;
    line-height: 32px;
}

p {
    font-size: 20px;
    line-height: 32px;
    font-family: 'sf_ui_displayregular';
}

// Buttons
.button-r {
    font-size: 28px;
    line-height: 52px;
    background: transparent;
    border-radius: 100px;
    padding: 18px 50px;
    min-height: 66px;
    text-decoration: none;
    font-family: $garamond;
    transition: all 0.1s ease-in-out;

    @media screen and (max-width: 460px) {
        font-size: 22px;
    }

    @media screen and (max-width: 375px) {
        font-size: 18px;
    }

    &__red {
        color: $red;
        border: 1px solid $red;

        &:hover {
            color: #fff;
            background: $red;
        }
    }

    &__white {
        color: #fff;
        border: 1px solid #fff;

        &:hover {
            color: $black;
            background: #fff;
        }
    }
}


// Grid
.row {
    width: 100%;
    max-width: 1100px;
    display: block;
    margin: 100px auto;
    @include clearfix();

    &:first-of-type {
        margin-top: 0;
    }

    @include below(640px) {
        margin: 60px auto;
    }
}

.single {
    margin: 0 auto 100px auto;
}

.slide__inner {
    position: relative;
}

.small-image-text {
    font-size: 13px;
    color: #fff;
    font-style: italic;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    padding-left: 20px;
}

select::-ms-expand {
    display: none;
}

.back-to-top {
    display: none;
    position: fixed;
    bottom: 30px;
    width: 50px;
    height: 50px;
    right: 2%;
    z-index: 999;
    background: #fff;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    border: 1px solid $black;
    transition: all .1s ease-in-out;
    animation: fade .5s ease;

    &:hover {
        background: $black;
        color: #fff;
    }
}

.visible {
    display: block;
}

@keyframes fade {
    from {
        opacity: 0;
        bottom: -40px;
    }
    to {
        opacity: 1;
        bottom: 30px;
    }
}

@font-face {
  font-family: 'GaramondPremrPro-Med';
  src: url('../fonts/GaramondPremrPro-Med.eot?#iefix') format('embedded-opentype'),  url('../fonts/GaramondPremrPro-Med.otf')  format('opentype'),
	     url('../fonts/GaramondPremrPro-Med.woff') format('woff'), url('../fonts/GaramondPremrPro-Med.ttf')  format('truetype'), url('../fonts/GaramondPremrPro-Med.svg#GaramondPremrPro-Med') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'sf_ui_displayregular';
    src: url('../fonts/sf-ui-display-regular-webfont.woff2') format('woff2'),
         url('../fonts/sf-ui-display-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay-Bold';
  src: url('../fonts/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/SFUIDisplay-Bold.otf')  format('opentype'),
       url('../fonts/SFUIDisplay-Bold.woff') format('woff'), url('../fonts/SFUIDisplay-Bold.ttf')  format('truetype'), url('../fonts/SFUIDisplay-Bold.svg#SFUIDisplay-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

$garamond: 'GaramondPremrPro-Med';

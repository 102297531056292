.text-regular {
    width: 100%;
    margin: 0 auto;
    max-width: 570px;

    @include below(640px) {
        padding: 0 20px;
    }
}

.text-regular__title {
    margin-bottom: 30px;
}

.text-regular__content {
    p {
        font-size: 20px;
        line-height: 32px;
        margin: 20px 0;

        &:first-of-type {
            margin-top: 0;
        }

        @include below(420px) {
            font-size: 16px;
            line-height: 26px;
        }

        a {
            color: $red;
        }
    }
}

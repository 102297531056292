.faq {

}

.faq__title {
    text-align: center;
    margin: 0 0 50px 0;
}

.faq__accordion {
    border-left: 1px solid #E4E4E4;
    border-right: 1px solid #E4E4E4;
    border-bottom: 1px solid #E4E4E4;

    &:first-of-type {
        border-top: 1px solid #E4E4E4;
    }
}

.faq__accordion-header {
    width: 100%;
    font-size: 28px;
    line-height: 32px;
    padding: 35px 40px;
    font-family: $garamond;
    cursor: pointer;
    display: table;

    @include below(520px) {
        font-size: 24px;
    }
    @include below(460px) {
        padding: 35px 30px;
    }
}

.faq__accordion-text {
    width: 80%;
    font-size: 20px;
    line-height: 32px;
    font-family: 'sf_ui_displayregular';
    padding: 0px 40px 40px 40px;

    p {
        margin: 20px 0;

        a {
            color: $red;
        }

        @media screen and (max-width: 420px) {
            font-size: 16px;
        }
    }

    @include below(520px) {
        font-size: 16px;
        line-height: 28px;
    }
    @include below(460px) {
        padding: 0 30px 30px 30px;
        width: 100%;
    }
}

.faq__accordion-cross {
    width: 18px;
    height: 18px;
    position: relative;
    display: table-cell;
    transition: all .3s ease-in-out;
    background-repeat: no-repeat;
    background-position: center center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background-image: url('../img/accordioncross.png');

    &.is-active {
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.faq__accordion-title {
    width: 95%;
    padding: 0 30px 0 0;
    display: table-cell;
}

.dropdown {
    width: 100%;
    font-size: 28px;
    line-height: 52px;
    font-family: $garamond;
}

.dropdown__select {
    border: none;
    padding: 0 80px 0 30px;
    margin: 0 20px 0 10px;
    min-height: 66px;
    background: #F6F6F6;
    border-radius: 100px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background-image: url('../img/drop-arrow.png');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;

    &:focus {
        outline: none;
    }
}

#width_tmp_select {
  display: none;
}

.front-dropdown {
    @media screen and (max-width: 460px) {
        font-size: 26px;
    }
}

.footer {
    height: 350px;
    position: relative;
    margin: 0 auto;
    border-top: 1px solid $black;

    @include below(520px) {
        height: 150px;
    }
}

.footer__logo {
    display: block;
    font-size: 62px;
    text-align: center;
    font-family: $garamond;
    @include vertical-align();

    @include below(520px) {
        font-size: 32px;
    }

    a {
        color: $black;
        text-decoration: none;
    }
}

.single-article {
    width: 100%;
    max-width: 730px;

    @include below(1140px) {
        padding: 0 20px;
    }
}

.single-article__title {
    margin: 40px 0 60px 0;

    @include below(640px) {
        margin: 40px 0;
    }
}

.single-article__text {
    width: 100%;
    max-width: 580px;

    p {
        margin: 20px 0;
        font-size: 20px;
        line-height: 32px;

        @include below(640px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
}

.single-article__image {
    margin: 40px 0;
    max-width: 100%;

    @include below(640px) {
        margin: 10px 0;
    }
}

.single-article__back {
    color: $red;
    font-size: 16px;
    cursor: pointer;
    margin: 60px 0 20px 0;
    display: inline-block;
    text-decoration: none;

    &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 10px;
        transform: rotate(180deg);
        transition: transform .2s ease;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/rightarrow.png');
    }

    &:hover {
        &:before {
            transform: translateX(-5px) rotate(180deg);
        }
    }
}

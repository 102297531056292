.image-slider {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    border: 1px solid #E4E4E4;
}

.image-slider__inner {
    @include clearfix();
}

.image-slider__content {
    float: left;
    width: 33%;
    height: 100%;
    padding: 40px;
    position: relative;

    @include below(768px) {
        width: 100%;
    }
}

.image-slider__image {
    float: right;
    width: 67%;

    @include below(768px) {
        width: 100%;
    }
}

.image-slider__title {
    margin-bottom: 30px;
}

.image-slider__text {
    font-size: 16px;
    line-height: 26px;
}

.image-slider__text-title {
    display: block;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
}

.flickity-viewport {
    width: 100%;

    &:focus {
        outline: none !important;
    }

    @include below(640px) {
        height: 300px;
    }
}

.image__slider {
    width: 67%;
    position: relative;
    float: left;

    @include below(768px) {
        width: 100%;
    }
}

.flickity-slider {
    transform: none !important;
}

.flick {
    width: 100%;
    opacity: 0;
    z-index: -1;
    left: 0 !important;
    transition: all 2.5s ease;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 483px;

    @include below(640px) {
        height: 300px;
    }

    .flick-anim {
        opacity: 0;
    }

    &.is-selected {
        z-index: 0;
        opacity: 1;
        transition: all 2s ease;

        .flick-anim {
            opacity: 1;
        }
    }
}

.slider-buttons {
    z-index: 10;
    display: table;
    position: relative;
    margin-bottom: 30px;
}

.button-s {
    width: 30px;
    height: 30px;
    display: table-cell;
    position: relative;
    border: 1px solid $red;
    background: transparent;

    &:focus {
        outline: none;
    }

    &--previous {
        &:after {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            top: 0;
            left: 0;
            position: absolute;
            transition: all .2s ease-in-out;
            transform: rotate(180deg);
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('../img/next-arrow.png');
        }
    }
    &--next {
        &:after {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            top: 0;
            left: 0;
            position: absolute;
            transition: all .2s ease-in-out;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('../img/next-arrow.png');
        }
    }

    &:hover {
        background-color: $red;
    }
    &--next:hover,
    &--previous:hover {
        &:after {
            background-image: url('../img/next-arrow-white.png');
        }
    }
}

.slider-count {
    font-size: 16px;
    padding: 0 10px;
    display: table-cell;
    vertical-align: middle;
}

.header {
    width: 100%;
    max-width: 1100px;
    display: block;
    overflow: hidden;
    position: relative;
    margin: 0 auto 60px auto;

    &:focus {
        outline: none !important;
    }

    img {
        display: block;
        max-width: 100%;
    }

    .flickity-viewport {
        height: auto;

        &:focus {
            outline: none !important;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::after {
            padding-top: 40%;
        }
    }
}

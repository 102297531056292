.download {
    display: block;
}

.download__title {
    text-align: center;
    margin-bottom: 50px;
}

.download__col {
    width: 33.33%;
    padding: 10px;
    display: block;
    float: left;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        transform: translateY(-4px);
        -moz-transform: translateY(-4px);
        -webkit-transform: translateY(-4px);

        .download__button {
            &:after {
                animation: bounce 2s infinite;
                -webkit-animation: bounce 2s infinite;
                -moz-animation: bounce 2s infinite1
            }
        }
    }

    @media screen and (max-width: 840px) {
        width: 50%;
    }
    @media screen and (max-width: 620px) {
        width: 100%;
    }
}

.download__col-inner {
    width: 100%;
    min-height: 350px;
    position: relative;
    border: 1px solid #E4E4E4;
}

.download__col-content {
    width: 100%;
    text-align: center;
    @include align-both(absolute);

    img {
        margin: 0 auto;
        max-width: 100%;
        display: block;
    }
}

.download__col-text {
    display: block;
    color: $black;
    font-size: 16px;
    line-height: 26px;
    max-width: 90%;
    margin: 30px auto 0 auto;
}

.download__button {
    top: -1px;
    left: -1px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: absolute;
    border: 1px solid $red;

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/downarrow.png');
    }
}

@-webkit-keyframes bounce {
 0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
 40% {-webkit-transform: translateY(-10px);}
 60% {-webkit-transform: translateY(-5px);}
}

@-moz-keyframes bounce {
 0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
 40% {-moz-transform: translateY(-10px);}
 60% {-moz-transform: translateY(-5px);}
}

@keyframes bounce {
 0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
 40% {transform: translateY(-10px);}
 60% {transform: translateY(-5px);}
}

@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased; }
  body * {
    box-sizing: border-box; }

a:focus {
  outline: 0; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal; }

ul {
  margin: 0;
  padding: 0; }

@font-face {
  font-family: 'GaramondPremrPro-Med';
  src: url("../fonts/GaramondPremrPro-Med.eot?#iefix") format("embedded-opentype"), url("../fonts/GaramondPremrPro-Med.otf") format("opentype"), url("../fonts/GaramondPremrPro-Med.woff") format("woff"), url("../fonts/GaramondPremrPro-Med.ttf") format("truetype"), url("../fonts/GaramondPremrPro-Med.svg#GaramondPremrPro-Med") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sf_ui_displayregular';
  src: url("../fonts/sf-ui-display-regular-webfont.woff2") format("woff2"), url("../fonts/sf-ui-display-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SFUIDisplay-Bold';
  src: url("../fonts/SFUIDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay-Bold.otf") format("opentype"), url("../fonts/SFUIDisplay-Bold.woff") format("woff"), url("../fonts/SFUIDisplay-Bold.ttf") format("truetype"), url("../fonts/SFUIDisplay-Bold.svg#SFUIDisplay-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */
/**
 * Retina
 */
body,
html {
  width: 100%;
  height: 100%;
  position: relative; }

body {
  animation: fadeIn 1.5s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

h2, h3,
h4 {
  font-family: 'GaramondPremrPro-Med'; }

h2 {
  font-size: 46px;
  line-height: 52px; }
  @media only screen and (max-width: 640px) {
    h2 {
      font-size: 36px;
      line-height: 42px; } }

h3 {
  font-size: 36px;
  line-height: 52px; }
  @media only screen and (max-width: 420px) {
    h3 {
      font-size: 30px;
      line-height: 42px; } }
  @media only screen and (max-width: 320px) {
    h3 {
      font-size: 26px; } }

h4 {
  font-size: 28px;
  line-height: 32px; }

p  {
  font-size: 20px;
  line-height: 32px;
  font-family: 'sf_ui_displayregular'; }

.button-r {
  font-size: 28px;
  line-height: 52px;
  background: transparent;
  border-radius: 100px;
  padding: 18px 50px;
  min-height: 66px;
  text-decoration: none;
  font-family: "GaramondPremrPro-Med";
  transition: all 0.1s ease-in-out; }
  @media screen and (max-width: 460px) {
    .button-r {
      font-size: 22px; } }
  @media screen and (max-width: 375px) {
    .button-r {
      font-size: 18px; } }
  .button-r__red {
    color: #692C3D;
    border: 1px solid #692C3D; }
    .button-r__red:hover {
      color: #fff;
      background: #692C3D; }
  .button-r__white {
    color: #fff;
    border: 1px solid #fff; }
    .button-r__white:hover {
      color: #000000;
      background: #fff; }

.row {
  width: 100%;
  max-width: 1100px;
  display: block;
  margin: 100px auto; }
  .row:before, .row:after {
    content: "";
    display: table; }
  .row:after {
    clear: both; }
  .row:first-of-type {
    margin-top: 0; }
  @media only screen and (max-width: 640px) {
    .row {
      margin: 60px auto; } }

.single {
  margin: 0 auto 100px auto; }

.slide__inner {
  position: relative; }

.small-image-text {
  font-size: 13px;
  color: #fff;
  font-style: italic;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  padding-left: 20px; }

select::-ms-expand {
  display: none; }

.back-to-top {
  display: none;
  position: fixed;
  bottom: 30px;
  width: 50px;
  height: 50px;
  right: 2%;
  z-index: 999;
  background: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  border: 1px solid #000000;
  transition: all .1s ease-in-out;
  animation: fade .5s ease; }
  .back-to-top:hover {
    background: #000000;
    color: #fff; }

.visible {
  display: block; }

@keyframes fade {
  from {
    opacity: 0;
    bottom: -40px; }
  to {
    opacity: 1;
    bottom: 30px; } }

.startpage {
  width: 100%;
  height: 100%;
  position: relative; }
  .startpage:before, .startpage:after {
    content: "";
    display: table; }
  .startpage:after {
    clear: both; }

.startpage__col {
  width: 50%;
  height: 100%;
  display: block;
  float: left; }
  .startpage__col:first-of-type {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  @media only screen and (max-width: 980px) {
    .startpage__col {
      width: 100%;
      height: auto; }
      .startpage__col:first-of-type {
        height: 40%; } }

.startpage__content {
  width: 100%;
  padding: 0 20px;
  text-align: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  .startpage__content .dropdown {
    margin-top: 70px; }
    @media only screen and (max-width: 460px) {
      .startpage__content .dropdown {
        margin-top: 40px; } }
  @media only screen and (max-width: 980px) {
    .startpage__content {
      top: auto;
      left: auto;
      margin: 60px 0;
      transform: none; } }

.startpage__content-title {
  margin-bottom: 35px; }

.startpage__content-text {
  font-size: 20px;
  line-height: 32px;
  max-width: 490px;
  margin: 0 auto;
  font-family: 'sf_ui_displayregular'; }
  @media only screen and (max-width: 460px) {
    .startpage__content-text {
      font-size: 16px;
      line-height: 28px; } }

.startpage__logo {
  color: #fff;
  display: block;
  font-size: 72px;
  text-align: center;
  font-family: "GaramondPremrPro-Med";
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  -webkit-text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  animation: fadeInDown 2s; }
  @media only screen and (max-width: 460px) {
    .startpage__logo {
      font-size: 42px; } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    top: 45%; }
  25% {
    opacity: 0.25; }
  50% {
    opacity: 0.5; }
  75% {
    opacity: 0.75; }
  100% {
    opacity: 1;
    top: 50%; } }

@media only screen and (max-width: 1380px) {
  .startpage__content .dropdown__select {
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 20px auto 0 auto; } }

@media only screen and (max-width: 460px) {
  .startpage__content .dropdown__select {
    max-width: none; } }

@media only screen and (max-width: 1380px) {
  .startpage__content .button-r {
    display: block;
    max-width: 70%;
    margin: 40px auto; } }

@media only screen and (max-width: 460px) {
  .startpage__content .button-r {
    max-width: none;
    padding: 5px 50px;
    min-height: none; } }

.dropdown {
  width: 100%;
  font-size: 28px;
  line-height: 52px;
  font-family: "GaramondPremrPro-Med"; }

.dropdown__select {
  border: none;
  padding: 0 80px 0 30px;
  margin: 0 20px 0 10px;
  min-height: 66px;
  background: #F6F6F6;
  border-radius: 100px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background-image: url("../img/drop-arrow.png");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0; }
  .dropdown__select:focus {
    outline: none; }

#width_tmp_select {
  display: none; }

@media screen and (max-width: 460px) {
  .front-dropdown {
    font-size: 26px; } }

.navigationbar {
  width: 100%;
  height: 75px;
  background: #fff;
  position: relative;
  z-index: 10;
  line-height: 75px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06); }
  @media only screen and (max-width: 1100px) {
    .navigationbar {
      padding: 0 20px; } }

.navigationbar__logo {
  float: left;
  width: 20%;
  font-size: 30px;
  line-height: 75px;
  display: inline-block; }
  .navigationbar__logo a {
    color: #000000;
    text-decoration: none; }
  @media only screen and (max-width: 560px) {
    .navigationbar__logo {
      font-size: 26px; } }

@media only screen and (max-width: 560px) {
  .dropdown__text {
    display: none; } }

.navigationbar__nav {
  width: 80%;
  float: right;
  text-align: right;
  position: relative;
  display: inline-block; }
  .navigationbar__nav a {
    color: #000000;
    text-decoration: none; }

.navigationbar__nav .dropdown {
  font-size: 20px;
  line-height: 75px;
  display: inline;
  font-family: 'sf_ui_displayregular'; }
  @media only screen and (max-width: 640px) {
    .navigationbar__nav .dropdown {
      font-size: 16px; } }

.navigationbar__nav .dropdown .dropdown__select {
  background: #fff;
  min-height: auto;
  width: auto;
  padding: 0 30px 0 7px;
  margin: 0;
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url("../img/dropdownarrowred.png"); }
  @media only screen and (max-width: 420px) {
    .navigationbar__nav .dropdown .dropdown__select {
      max-width: 50%; } }

.navigationbar__col {
  font-size: 20px;
  line-height: 75px;
  margin-left: 25px;
  cursor: pointer;
  display: inline-block; }
  .navigationbar__col:after {
    content: '';
    top: 5px;
    width: 25px;
    height: 25px;
    margin-left: 15px;
    position: relative;
    display: inline-block;
    transition: top .1s ease-in-out;
    background-position: center center;
    background-repeat: no-repeat; }
  .navigationbar__col--contact:after {
    background-image: url("../img/contactimg.png"); }
  .navigationbar__col--search:after {
    background-image: url("../img/searchimg.png"); }
  .navigationbar__col:hover:after {
    top: 3px; }
  @media only screen and (max-width: 960px) {
    .navigationbar__col {
      display: none; } }

.navigationbar__search {
  font-size: 20px;
  line-height: 75px;
  margin-left: 25px;
  cursor: pointer;
  display: inline-block; }

.search-bar {
  top: -75px;
  width: 100%;
  z-index: 9;
  height: 100px;
  background: #fff;
  position: absolute;
  text-align: center;
  transition: top .2s ease-in-out;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.1); }
  .search-bar form {
    height: 100%; }
  .search-bar input {
    width: 100%;
    height: 100%;
    text-align: center;
    max-width: 1100px;
    margin: 0 auto;
    border: none;
    font-size: 38px;
    color: #000000;
    font-family: "GaramondPremrPro-Med"; }
    .search-bar input:focus {
      outline: none; }
  .search-bar.is-active {
    top: 75px; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000000; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000000;
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000000;
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000; }

.header {
  width: 100%;
  max-width: 1100px;
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0 auto 60px auto; }
  .header:focus {
    outline: none !important; }
  .header img {
    display: block;
    max-width: 100%; }
  .header .flickity-viewport {
    height: auto; }
    .header .flickity-viewport:focus {
      outline: none !important; }
    .header .flickity-viewport::before, .header .flickity-viewport::after {
      content: "";
      display: block; }
    .header .flickity-viewport::after {
      padding-top: 40%; }

.text-module {
  width: 100%;
  max-width: 575px;
  margin: 0 auto; }
  @media only screen and (max-width: 640px) {
    .text-module {
      padding: 0 20px; } }

.text-module__title {
  max-width: 467px;
  text-align: center;
  margin: 0px auto 60px auto; }
  @media only screen and (max-width: 420px) {
    .text-module__title {
      margin-bottom: 40px; } }

.text-module__text {
  font-size: 20px;
  line-height: 32px;
  font-family: 'sf_ui_displayregular'; }
  @media only screen and (max-width: 640px) {
    .text-module__text {
      font-size: 16px;
      line-height: 26px; } }
  .text-module__text a {
    color: #692C3D; }

.text-regular {
  width: 100%;
  margin: 0 auto;
  max-width: 570px; }
  @media only screen and (max-width: 640px) {
    .text-regular {
      padding: 0 20px; } }

.text-regular__title {
  margin-bottom: 30px; }

.text-regular__content p {
  font-size: 20px;
  line-height: 32px;
  margin: 20px 0; }
  .text-regular__content p:first-of-type {
    margin-top: 0; }
  @media only screen and (max-width: 420px) {
    .text-regular__content p {
      font-size: 16px;
      line-height: 26px; } }
  .text-regular__content p a {
    color: #692C3D; }

.faq__title {
  text-align: center;
  margin: 0 0 50px 0; }

.faq__accordion {
  border-left: 1px solid #E4E4E4;
  border-right: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4; }
  .faq__accordion:first-of-type {
    border-top: 1px solid #E4E4E4; }

.faq__accordion-header {
  width: 100%;
  font-size: 28px;
  line-height: 32px;
  padding: 35px 40px;
  font-family: "GaramondPremrPro-Med";
  cursor: pointer;
  display: table; }
  @media only screen and (max-width: 520px) {
    .faq__accordion-header {
      font-size: 24px; } }
  @media only screen and (max-width: 460px) {
    .faq__accordion-header {
      padding: 35px 30px; } }

.faq__accordion-text {
  width: 80%;
  font-size: 20px;
  line-height: 32px;
  font-family: 'sf_ui_displayregular';
  padding: 0px 40px 40px 40px; }
  .faq__accordion-text p {
    margin: 20px 0; }
    .faq__accordion-text p a {
      color: #692C3D; }
    @media screen and (max-width: 420px) {
      .faq__accordion-text p {
        font-size: 16px; } }
  @media only screen and (max-width: 520px) {
    .faq__accordion-text {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (max-width: 460px) {
    .faq__accordion-text {
      padding: 0 30px 30px 30px;
      width: 100%; } }

.faq__accordion-cross {
  width: 18px;
  height: 18px;
  position: relative;
  display: table-cell;
  transition: all .3s ease-in-out;
  background-repeat: no-repeat;
  background-position: center center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background-image: url("../img/accordioncross.png"); }
  .faq__accordion-cross.is-active {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.faq__accordion-title {
  width: 95%;
  padding: 0 30px 0 0;
  display: table-cell; }

.cta-module {
  width: 100%;
  overflow: hidden;
  position: relative; }
  .cta-module.cta-module__quote {
    height: 415px;
    background: #F7F7F7; }
    .cta-module.cta-module__quote .cta-module__title {
      color: #000000;
      margin-bottom: 0; }
  @media only screen and (max-width: 890px) {
    .cta-module {
      height: 415px;
      background-size: cover;
      background-position: center center; } }

.cta-module__image {
  max-width: 100%;
  display: block;
  z-index: 0;
  position: relative;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  transition: all 8s ease; }
  @media only screen and (max-width: 890px) {
    .cta-module__image {
      display: none; } }

.cta-module:hover .cta-module__image {
  -ms-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.cta-module__inner {
  z-index: 3;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  @media only screen and (max-width: 890px) {
    .cta-module__inner {
      width: 100%; } }

.cta-module__title {
  color: #fff;
  max-width: 530px;
  font-size: 26px;
  font-style: italic;
  line-height: 32px;
  margin-bottom: 45px;
  font-family: "GaramondPremrPro-Med"; }
  @media only screen and (max-width: 890px) {
    .cta-module__title {
      width: 90%;
      max-width: none;
      margin: 0 auto 40px auto; } }
  @media only screen and (max-width: 460px) {
    .cta-module__title {
      font-size: 18px;
      line-height: 26px; } }

.cta-module__inner .button-r {
  line-height: 66px;
  padding: 0 50px;
  display: inline-block;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; }

.cta-module__overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.3); }

.article {
  width: 100%; }

.article__title {
  text-align: center;
  margin-bottom: 50px; }

.article__col {
  width: 50%;
  float: left;
  padding: 10px;
  display: block;
  float: left;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease; }
  .article__col:hover {
    transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -webkit-transform: translateY(-4px); }
    .article__col:hover .article__button:after {
      margin-left: 25px !important; }
  @media only screen and (max-width: 520px) {
    .article__col {
      width: 100%; } }

.article__col-inner {
  width: 100%;
  color: #000;
  padding: 60px 40px;
  position: relative;
  border: 1px solid #E4E4E4; }

.article__col-title {
  margin-bottom: 30px; }

.article__excerpt {
  font-size: 16px;
  max-width: 295px;
  line-height: 26px; }

.article__button {
  color: #692C3D;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px; }
  .article__button:after {
    content: '';
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-left: 20px;
    transition: margin-left 0.2s ease;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/rightarrow.png"); }

.image-slider {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid #E4E4E4; }

.image-slider__inner:before, .image-slider__inner:after {
  content: "";
  display: table; }

.image-slider__inner:after {
  clear: both; }

.image-slider__content {
  float: left;
  width: 33%;
  height: 100%;
  padding: 40px;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .image-slider__content {
      width: 100%; } }

.image-slider__image {
  float: right;
  width: 67%; }
  @media only screen and (max-width: 768px) {
    .image-slider__image {
      width: 100%; } }

.image-slider__title {
  margin-bottom: 30px; }

.image-slider__text {
  font-size: 16px;
  line-height: 26px; }

.image-slider__text-title {
  display: block;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px; }

.flickity-viewport {
  width: 100%; }
  .flickity-viewport:focus {
    outline: none !important; }
  @media only screen and (max-width: 640px) {
    .flickity-viewport {
      height: 300px; } }

.image__slider {
  width: 67%;
  position: relative;
  float: left; }
  @media only screen and (max-width: 768px) {
    .image__slider {
      width: 100%; } }

.flickity-slider {
  transform: none !important; }

.flick {
  width: 100%;
  opacity: 0;
  z-index: -1;
  left: 0 !important;
  transition: all 2.5s ease;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 483px; }
  @media only screen and (max-width: 640px) {
    .flick {
      height: 300px; } }
  .flick .flick-anim {
    opacity: 0; }
  .flick.is-selected {
    z-index: 0;
    opacity: 1;
    transition: all 2s ease; }
    .flick.is-selected .flick-anim {
      opacity: 1; }

.slider-buttons {
  z-index: 10;
  display: table;
  position: relative;
  margin-bottom: 30px; }

.button-s {
  width: 30px;
  height: 30px;
  display: table-cell;
  position: relative;
  border: 1px solid #692C3D;
  background: transparent; }
  .button-s:focus {
    outline: none; }
  .button-s--previous:after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    position: absolute;
    transition: all .2s ease-in-out;
    transform: rotate(180deg);
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/next-arrow.png"); }
  .button-s--next:after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    position: absolute;
    transition: all .2s ease-in-out;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/next-arrow.png"); }
  .button-s:hover {
    background-color: #692C3D; }
  .button-s--next:hover:after, .button-s--previous:hover:after {
    background-image: url("../img/next-arrow-white.png"); }

.slider-count {
  font-size: 16px;
  padding: 0 10px;
  display: table-cell;
  vertical-align: middle; }

.flickity-slider {
  position: relative; }

.video__overlay {
  width: 100%;
  height: 100%;
  position: relative; }

.video__overlay-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  @media only screen and (max-width: 640px) {
    .video__overlay-inner {
      width: 100%;
      padding: 0 15px; } }

.video__button {
  width: 88px;
  height: 88px;
  display: inline-block;
  background: #fff;
  position: relative;
  border-radius: 100%;
  transition: all .2s ease-in-out;
  background-image: url("../img/playbutton.png");
  background-position: center center;
  background-repeat: no-repeat; }
  .video__button:hover {
    background: #692C3D;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/playbuttonwhite.png"); }
  @media only screen and (max-width: 640px) {
    .video__button {
      width: 68px;
      height: 68px; } }

.video__text {
  color: #fff;
  display: block;
  font-size: 28px;
  font-style: italic;
  margin-top: 25px;
  font-family: "GaramondPremrPro-Med"; }
  @media only screen and (max-width: 640px) {
    .video__text {
      font-size: 22px; } }

.single-article {
  width: 100%;
  max-width: 730px; }
  @media only screen and (max-width: 1140px) {
    .single-article {
      padding: 0 20px; } }

.single-article__title {
  margin: 40px 0 60px 0; }
  @media only screen and (max-width: 640px) {
    .single-article__title {
      margin: 40px 0; } }

.single-article__text {
  width: 100%;
  max-width: 580px; }
  .single-article__text p {
    margin: 20px 0;
    font-size: 20px;
    line-height: 32px; }
    @media only screen and (max-width: 640px) {
      .single-article__text p {
        font-size: 16px;
        line-height: 26px; } }

.single-article__image {
  margin: 40px 0;
  max-width: 100%; }
  @media only screen and (max-width: 640px) {
    .single-article__image {
      margin: 10px 0; } }

.single-article__back {
  color: #692C3D;
  font-size: 16px;
  cursor: pointer;
  margin: 60px 0 20px 0;
  display: inline-block;
  text-decoration: none; }
  .single-article__back:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    transform: rotate(180deg);
    transition: transform .2s ease;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/rightarrow.png"); }
  .single-article__back:hover:before {
    transform: translateX(-5px) rotate(180deg); }

.download {
  display: block; }

.download__title {
  text-align: center;
  margin-bottom: 50px; }

.download__col {
  width: 33.33%;
  padding: 10px;
  display: block;
  float: left;
  cursor: pointer;
  transition: all 0.2s ease; }
  .download__col:hover {
    transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -webkit-transform: translateY(-4px); }
    .download__col:hover .download__button:after {
      animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      -moz-animation: bounce 2s infinite1; }
  @media screen and (max-width: 840px) {
    .download__col {
      width: 50%; } }
  @media screen and (max-width: 620px) {
    .download__col {
      width: 100%; } }

.download__col-inner {
  width: 100%;
  min-height: 350px;
  position: relative;
  border: 1px solid #E4E4E4; }

.download__col-content {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  .download__col-content img {
    margin: 0 auto;
    max-width: 100%;
    display: block; }

.download__col-text {
  display: block;
  color: #000000;
  font-size: 16px;
  line-height: 26px;
  max-width: 90%;
  margin: 30px auto 0 auto; }

.download__button {
  top: -1px;
  left: -1px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  border: 1px solid #692C3D; }
  .download__button:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/downarrow.png"); }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px); } }

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0); }
  40% {
    -moz-transform: translateY(-10px); }
  60% {
    -moz-transform: translateY(-5px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-10px); }
  60% {
    transform: translateY(-5px); } }

.search__list {
  width: 100%;
  display: inline-block; }

.search__title {
  margin: 80px 0;
  text-align: center; }

.footer {
  height: 350px;
  position: relative;
  margin: 0 auto;
  border-top: 1px solid #000000; }
  @media only screen and (max-width: 520px) {
    .footer {
      height: 150px; } }

.footer__logo {
  display: block;
  font-size: 62px;
  text-align: center;
  font-family: "GaramondPremrPro-Med";
  position: relative;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (max-width: 520px) {
    .footer__logo {
      font-size: 32px; } }
  .footer__logo a {
    color: #000000;
    text-decoration: none; }

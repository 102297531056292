.article {
    width: 100%;
}

.article__title {
    text-align: center;
    margin-bottom: 50px;
}

.article__col {
    width: 50%;
    float: left;
    padding: 10px;
    display: block;
    float: left;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
        transform: translateY(-4px);
        -moz-transform: translateY(-4px);
        -webkit-transform: translateY(-4px);

        .article__button {
            &:after {
                margin-left: 25px !important;
            }
        }
    }

    @include below(520px) {
        width: 100%;
    }
}

.article__col-inner {
    width: 100%;
    color: #000;
    padding: 60px 40px;
    position: relative;
    border: 1px solid #E4E4E4;
}

.article__col-title {
    margin-bottom: 30px;
}

.article__excerpt {
    font-size: 16px;
    max-width: 295px;
    line-height: 26px;
}

.article__button {
    color: $red;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;

    &:after {
        content: '';
        display: inline-block;
        width: 11px;
        height: 11px;
        margin-left: 20px;
        transition: margin-left 0.2s ease;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/rightarrow.png');
    }
}

.cta-module {
    width: 100%;
    overflow: hidden;
    position: relative;

    &.cta-module__quote {
        height: 415px;
        background: #F7F7F7;

        .cta-module__title {
            color: $black;
            margin-bottom: 0;
        }
    }

    @include below(890px) {
        height: 415px;
        background-size: cover;
        background-position: center center;
    }
}

.cta-module__image {
    max-width: 100%;
    display: block;
    z-index: 0;
    position: relative;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: all 8s ease;

    @include below(890px) {
        display: none;
    }
}

.cta-module:hover .cta-module__image {
    -ms-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-transform: scale(1.05, 1.05);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.cta-module__inner {
    z-index: 3;
    text-align: center;
    @include align-both(absolute);

    @include below(890px) {
        width: 100%;
    }
}

.cta-module__title {
    color: #fff;
    max-width: 530px;
    font-size: 26px;
    font-style: italic;
    line-height: 32px;
    margin-bottom: 45px;
    font-family: $garamond;

    @include below(890px) {
        width: 90%;
        max-width: none;
        margin: 0 auto 40px auto;
    }

    @include below(460px) {
        font-size: 18px;
        line-height: 26px;
    }
}

.cta-module__inner .button-r {
    line-height: 66px;
    padding: 0 50px;
    display: inline-block;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.cta-module__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    position: absolute;
    background: rgba(#000, 0.3);
}

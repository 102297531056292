.navigationbar {
    width: 100%;
    height: 75px;
    background: #fff;
    position: relative;
    z-index: 10;
    line-height: 75px;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);

    @include below(1100px) {
        padding: 0 20px;
    }
}

.navigationbar__logo {
    float: left;
    width: 20%;
    font-size: 30px;
    line-height: 75px;
    display: inline-block;

    a {
        color: $black;
        text-decoration: none;
    }

    @include below(560px) {
        font-size: 26px;
    }
}

.dropdown__text {
    @include below(560px) {
        display: none;
    }
}

.navigationbar__nav {
    width: 80%;
    float: right;
    text-align: right;
    position: relative;
    display: inline-block;

    a {
        color: $black;
        text-decoration: none;
    }
}

.navigationbar__nav .dropdown {
    font-size: 20px;
    line-height: 75px;
    display: inline;
    font-family: 'sf_ui_displayregular';

    @include below(640px) {
        font-size: 16px;
    }
}

.navigationbar__nav .dropdown .dropdown__select {
    background: #fff;
    min-height: auto;
    width: auto;
    padding: 0 30px 0 7px;
    margin: 0;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('../img/dropdownarrowred.png');

    @include below(420px) {
        max-width: 50%;
    }
}

.navigationbar__col {
    font-size: 20px;
    line-height: 75px;
    margin-left: 25px;
    cursor: pointer;
    display: inline-block;

    &:after {
        content: '';
        top: 5px;
        width: 25px;
        height: 25px;
        margin-left: 15px;
        position: relative;
        display: inline-block;
        transition: top .1s ease-in-out;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &--contact:after {
        background-image: url('../img/contactimg.png');
    }
    &--search:after {
        background-image: url('../img/searchimg.png');
    }

    &:hover {
        &:after {
            top: 3px;
        }
    }

    @include below(960px) {
        display: none;
    }
}

.navigationbar__search {
    font-size: 20px;
    line-height: 75px;
    margin-left: 25px;
    cursor: pointer;
    display: inline-block;
}

.search-bar {
    top: -75px;
    width: 100%;
    z-index: 9;
    height: 100px;
    background: #fff;
    position: absolute;
    text-align: center;
    transition: top .2s ease-in-out;
    box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.1);

    form {
        height: 100%;
    }

    input {
        width: 100%;
        height: 100%;
        text-align: center;
        max-width: 1100px;
        margin: 0 auto;
        border: none;
        font-size: 38px;
        color: $black;
        font-family: $garamond;

        &:focus {
            outline: none;
        }
    }

    &.is-active {
        top: 75px;
    }
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $black;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $black;
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $black;
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $black;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: $black;
}
